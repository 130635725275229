import { nanoid } from 'nanoid';
import { utilizationColoringReasonsList } from '../store/constant';
import {
  getRoundedValues,
  getUpperLimitByComparingTwoMaxmimumElementsOfSameList
} from './upperLimit.utils';

export const getFieldNameAccordingToTypeTopTherepeuticsAndMedication = (
  type = '',
  subType = ''
) => {
  if (type === 'title') {
    if (subType === 'medication') {
      return 'medicationName';
    } else if (subType === 'therapeutic') {
      return 'therapeuticName';
    } else {
      return 'medicationName';
    }
  } else if (type === 'value') {
    if (subType === '$') {
      return 'monthlyUtilization';
    } else if (subType === '%') {
      return 'monthlyUtilizationPercent';
    } else {
      return 'spend';
    }
  }
};

export const getFieldNameAccordingToTypeTopPhysician = (
  type = '',
  subType = ''
) => {
  if (type === 'title') {
    return 'physicianName';
  } else if (type === 'value') {
    if (subType === '$') {
      return 'physicianSpend';
    } else if (subType === '%') {
      return 'percentOfSpend';
    } else {
      return 'spend';
    }
  }
};

export const formattedDataForTopPhysicians_Medications_Therapeutics = ({
  data,
  medicationSlug,
  medication,
  theraputic,
  activeTherapeuticTab,
  title,
  from
}) => {
  let graphData = [];
  const series = [];

  const allData = data;
  let titleFieldName = 'title';
  let valueFieldName = 'value';
  let barColorType = 'blue';

  if (from === 'TopTherepeuticsAndMedication') {
    titleFieldName = getFieldNameAccordingToTypeTopTherepeuticsAndMedication(
      'title',
      medicationSlug?.type === 'Medication' || medication
        ? 'therapeutic'
        : medicationSlug?.type === 'Theraputic' || theraputic
        ? 'medication'
        : ''
    );

    valueFieldName = getFieldNameAccordingToTypeTopTherepeuticsAndMedication(
      'value',
      activeTherapeuticTab[title] === '$ Spend'
        ? '$'
        : activeTherapeuticTab[title] === '% Spend'
        ? '%'
        : ''
    );

    barColorType = 'green';
  } else if (from === 'TopPhysician') {
    titleFieldName = getFieldNameAccordingToTypeTopPhysician(
      'title',
      medicationSlug?.type === 'Medication' || medication
        ? 'therapeutic'
        : medicationSlug?.type === 'Theraputic' || theraputic
        ? 'medication'
        : ''
    );

    valueFieldName = getFieldNameAccordingToTypeTopPhysician(
      'value',
      activeTherapeuticTab[title] === '$ Spend'
        ? '$'
        : activeTherapeuticTab[title] === '% Spend'
        ? '%'
        : ''
    );

    barColorType = 'blue';
  }

  const length = 7;

  for (let i = 0; i < length; i++) {
    if (allData?.length && allData[i]) {
      graphData.push({
        title: allData[i][titleFieldName],
        value: allData[i][valueFieldName],
        barColorType: barColorType
      });

      series.push(allData[i][valueFieldName]);
    } else {
      graphData.push({ title: '', value: 0 });
      series.push(0);
    }
  }

  const upperLimit =
    getUpperLimitByComparingTwoMaxmimumElementsOfSameList(series);

  graphData = graphData?.map(item => {
    if (upperLimit?.max) {
      if (item?.value === upperLimit?.max) {
        return { ...item, max: upperLimit?.r1 };
      } else {
        return { ...item, max: upperLimit?.r2 };
      }
    } else {
      return { ...item, max: upperLimit?.r1 };
    }
  });

  return graphData;
};

export const getMaxValueFromData = (graphData, maxFieldNames) => {
  const allValues = [];
  graphData?.forEach(item => {
    allValues.push(item[maxFieldNames[0]]);
    allValues.push(item[maxFieldNames[1]]);
  });

  return Math.max(...allValues);
};
export const getMedicationUtilizationColoringGraphs = (
  graphData = [],
  graphType = '',
  fieldName = '',
  maxFieldNames = [],
  modelUtilizationSupplement = [],
  includeDollar = '',
  includePercentage = ''
) => {
  const medicationsToBecolored =
    modelUtilizationSupplement?.map(item => ({
      ...item,
      concatenatedName: `${item?.genericName} ${item?.route}`
      // reasonId: 11001
    })) || [];

  const reasonIds = utilizationColoringReasonsList
    ?.filter(item => item?.type === graphType)
    ?.map(item => item?.reasonId);

  const medicationWithReasonIdToBecolored = medicationsToBecolored
    ?.filter(item => reasonIds?.includes(parseInt(item?.reasonId)))
    ?.map(item => item?.concatenatedName);

  return graphData?.map(item => ({
    ...item,
    includeDollar: includeDollar,
    includePercentage: includePercentage,
    max: includePercentage
      ? 100
      : getRoundedValues(
          item[maxFieldNames[0]],
          getMaxValueFromData(graphData, maxFieldNames)
        ),
    manualColor: medicationWithReasonIdToBecolored?.includes(item[fieldName])
  }));
};

export function modifyStringLastTwoCharacters(inputString = '') {
  // Extract the last two characters
  const lastTwoCharacters = inputString.slice(-2);

  // Remove the hyphen and trim the string
  const modifiedString = inputString.slice(0, -2).replace(/[-\s]+$/, '');

  // Create the final string with the last two characters in brackets
  const resultString = `${modifiedString} (${lastTwoCharacters.toUpperCase()})`;

  return resultString;
}

export const getViewAllParams = (
  hospitalFilter = [],
  routeFilters,
  ndcFilters,
  strengthFilters,
  therapeuticFilters
) => {
  const selectFilters = [];
  const filtersToBeNotIncluded = [];

  if (hospitalFilter?.length) {
    selectFilters.push({
      key: 'Facility',
      accessorKey: 'Hospital Name',
      selectFilters: hospitalFilter?.map(t => {
        return { label: t?.label, id: nanoid() };
      })
    });
  } else {
    filtersToBeNotIncluded.push({
      key: 'Facility',
      accessorKey: 'Hospital Name'
    });
  }

  if (Array.isArray(routeFilters)) {
    if (routeFilters?.length) {
      selectFilters.push({
        key: 'Medication',
        accessorKey: 'Route',
        selectFilters: routeFilters?.map(t => {
          return { label: t?.value, id: nanoid() };
        })
      });
    } else {
      filtersToBeNotIncluded.push({
        key: 'Medication',
        accessorKey: 'Route'
      });
    }
  }

  if (Array.isArray(ndcFilters)) {
    if (ndcFilters?.length) {
      selectFilters.push({
        key: 'Medication',
        accessorKey: 'NDC',
        selectFilters: ndcFilters?.map(t => {
          return { label: t?.value, id: nanoid() };
        })
      });
    } else {
      filtersToBeNotIncluded.push({
        key: 'Medication',
        accessorKey: 'NDC'
      });
    }
    
  }

  if (Array.isArray(strengthFilters)) {
    if (strengthFilters?.length) {
      selectFilters.push({
        key: 'Medication',
        accessorKey: 'Strength',
        selectFilters: strengthFilters?.map(t => {
          return { label: t?.value, id: nanoid() };
        })
      });
    } else {
      filtersToBeNotIncluded.push({
        key: 'Medication',
        accessorKey: 'Strength'
      });
    }
  }

  if (Array.isArray(therapeuticFilters)) {
    if (therapeuticFilters?.length) {
      selectFilters.push({
        key: 'Diagnosis',
        accessorKey: 'Primary Diagnosis',
        selectFilters: therapeuticFilters?.map(t => {
          return { label: t?.label, id: nanoid() };
        })
      });
    } else {
      filtersToBeNotIncluded.push({
        key: 'Diagnosis',
        accessorKey: 'Primary Diagnosis',
      });
    }
  }

  return { selectFilters, filtersToBeNotIncluded };
};
