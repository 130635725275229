import { decodeValue } from "./overview.utils";

export const getBackPath = (router) => {
    const previousPage = router?.query?.previousPage;

    const decodedValue = decodeValue(previousPage);

    if (decodedValue?.previousPage) {
      return decodedValue?.previousPage;
    }
  };