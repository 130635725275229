export const getUpperLimitByComparingTwoMaxmimumElementsOfSameList = list => {
  if (!list) {
    return 0;
  }

  if (list?.length) {
    if (list?.length == 1) {
      return { r1: roundToNearest100_500_1000(list[0]) };
    }
    if (list?.length >= 2) {
      const [highest, secondHighest] = maxAndSecondMax(list);
      const r1 = roundToNearest100_500_1000(highest);
      const r2 = roundToNearest100_500_1000(secondHighest);

      if (r1 / r2 < 10) {
        return { r1 };
      } else if (r1 / r2 >= 10) {
        return { max: highest, r1, r2 };
      }
    }
  }
};

export const getUpperLimitByComparingTwoMaxmimumElementsOfSameListWithoutRounding =
  list => {
    if (!list) {
      return 0;
    }

    if (list?.length) {
      if (list?.length == 1) {
        return { r1: list[0] };
      }
      if (list?.length >= 2) {
        const [highest, secondHighest] = maxAndSecondMax(list);
        const r1 = highest;
        const r2 = secondHighest;

        if (r1 / r2 < 10) {
          return { r1 };
        } else if (r1 / r2 >= 10) {
          return { max: highest, r1, r2 };
        }
      }
    }
  };

function maxAndSecondMax(arr) {
  let max = -Infinity;
  let secondMax = -Infinity;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > max) {
      secondMax = max;
      max = arr[i];
    } else if (arr[i] > secondMax) {
      secondMax = arr[i];
    }
  }
  return [max, secondMax];
}

export function roundToNearest100_500_1000(number) {
  let n = Math.ceil(Math.log10(number)); // take log with base 10
  let y = Math.round(number / Math.pow(10, n));
  if (y <= 0.5) {
    y = 0.5;
  } else {
    y = 1;
  }
  return y * Math.pow(10, n);
}

export const roundToNearest100 = number => {
  return Math.ceil(number / 100) * 100;
};

export const roundToNearest10 = number => {
  return Math.ceil(number / 10) * 10;
};

export function lengthOfStayUpperLimit(mx) {
  let mx_adj = 1.1 * mx;
  return 7 * Math.ceil(mx_adj / 7);
}

export function topTherapeuticsUpperLimit(list) {
  const roundedMaximums = list?.map(item => {
    return roundToNearest100_500_1000(Math.max(item?.before, item?.after));
  });

  const upperLimits =
    getUpperLimitByComparingTwoMaxmimumElementsOfSameListWithoutRounding(
      roundedMaximums
    );

  if (upperLimits?.max) {
    return list?.map(item => {
      if (
        roundToNearest100_500_1000(Math.max(item?.before, item?.after)) ==
        upperLimits?.max
      ) {
        return { ...item, max: upperLimits?.r1 };
      } else {
        return { ...item, max: upperLimits?.r2 };
      }
    });
  } else {
    return list?.map(item => ({ ...item, max: upperLimits?.r1 }));
  }
}

export const patientDemoGraphicAgeWeightUpperLimit = (
  before,
  after,
  multipliedBy = 1.1
) => {
  const max = Math.max(before, after);

  return roundToNearest10(multipliedBy * max);
};

export const physicianAnalogyLengthOfStayUpperLimits = (
  before = 0,
  after = 0
) => {
  return 7 * Math.ceil((Math.max(before, after) * 1.1) / 7);
};

export const getRoundedValues = (number1 = 0, number2 = 0, number3 = 0, number4 = 0) => {
  if (number1 || number2) {
    return roundToNearest100_500_1000(Math.max(number1, number2, number3, number4));
  }

  return 0;
};
