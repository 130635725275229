import { format } from 'url';
import {
  physicianAnalogyLengthOfStayUpperLimits,
  roundToNearest100_500_1000
} from './upperLimit.utils';
import { getMaxValueFromData } from './utilization.utils';
import { getBackPath } from './common.utils';

export const getPhysicianUpperlimitsWithData = data => {
  return data?.map(item => {
    if (item?.title === 'Drug Spend') {
      const filteredData = data?.filter(item => item?.title === 'Drug Spend');
      return {
        ...item,
        max: getMaxValueFromData(filteredData, ['before', 'after'])
      };
    }

    if (item?.title === 'Length Of Stay') {
      return {
        ...item,
        max: physicianAnalogyLengthOfStayUpperLimits(item?.before, item?.after)
      };
    }
    return {
      ...item,
      max: roundToNearest100_500_1000(Math.max(item?.before, item?.after))
    };
  });
};

export const getSubtitleForGeneralAccordingToTitle = ({ title, subtitle }) => {
  if (title === 'Drug Spend' && subtitle === 'Monthly') {
    return 'Monthly Drug Spend';
  } else if (title === 'Drug Spend' && subtitle === 'Per Patient') {
    return '';
  } else if (title === 'Patient Census' && subtitle === 'Monthly') {
    return 'Monthly Patient Volume';
  } else if (title === 'Length Of Stay' && subtitle === 'Average') {
    return 'Average Length Of Stay';
  } else if (title === 'Medication Expense Score') {
    return 'Medication Expense Score';
  }
};

export const getPhysicianParams = ({
  brandFilters,
  ndcFilters,
  strengthFilters,
  routeFilters,
  hospitalFilter,
  therapeuticsFilter,
  patientFilter,
  page
}) => {
  const params = {
    size: 10,
    page
  };

  if (brandFilters?.length) {
    params.brand = brandFilters?.map(item => item?.value);
  }

  if (ndcFilters?.length) {
    params.ndc = ndcFilters?.map(item => item?.value);
  }

  if (strengthFilters?.length) {
    params.strength = strengthFilters?.map(item => item?.value);
  }

  if (routeFilters?.length) {
    params.route = routeFilters?.map(item => item?.value);
  }

  if (hospitalFilter?.length) {
    params.hospitalIds = hospitalFilter?.map(item => item?.value);
  }

  if (therapeuticsFilter?.length) {
    params.normalizedDrgs = removeNullfromArray(therapeuticsFilter)?.map(
      item => item?.value
    );
  }

  if (patientFilter?.length) {
    params.patient = patientFilter?.map(item => item?.value);
  }

  return params;
};

export const fixTableStyling = (tableName, tableLength) => {
  const topMedicationDiv = document.querySelector(`[data-cy="${tableName}"]`);
  if (topMedicationDiv) {
    const tables = topMedicationDiv.querySelectorAll('table');
    tables?.forEach(table => {
      const tbody = table.querySelector('tbody');
      if (tbody) {
        const rows = tbody.querySelectorAll('tr');
        rows.forEach((row, index) => {
          const tds = row.querySelectorAll('td');

          for (let i = 0; i < tds.length; i++) {
            tds[i].style.borderRadius = '0';
            tds[i].style.border = 'none';
          }

          for (let i = 0; i < Math.min(2, tds.length); i++) {
            tds[i].style.backgroundColor = 'rgba(70, 86, 113, 0.07)';
            if (tds?.length > 1) {
              const firstTd = tds[0];
              const secondTd = tds[1];
              if (firstTd && secondTd) {
                if (index === 0) {
                  firstTd.style.borderTop = '1px solid #387ED0';
                  firstTd.style.borderLeft = '1px solid #387ED0';
                  firstTd.style.borderRadius = '8px 0px 0px 0px';

                  secondTd.style.borderTop = '1px solid #387ED0';
                  secondTd.style.borderRight = '1px solid #387ED0';
                  secondTd.style.borderRadius = '0px 8px 0px 0px';
                } else if (
                  (index + 1) % 5 === 0 ||
                  (tableLength && tableLength < 5 && index === tableLength - 1)
                ) {
                  firstTd.style.borderBottom = '1px solid #387ED0';
                  firstTd.style.borderLeft = '1px solid #387ED0';
                  firstTd.style.borderRadius = '0px 0px 0px 8px';

                  secondTd.style.borderBottom = '1px solid #387ED0';
                  secondTd.style.borderRight = '1px solid #387ED0';
                  secondTd.style.borderRadius = '0px 0px 8px 0px';
                } else {
                  firstTd.style.borderLeft = '1px solid #387ED0';
                  secondTd.style.borderRight = '1px solid #387ED0';
                }
              }
            }
          }
        });
      }
    });
  }
};

export const getHorizontalChartHeaderColor = (val, higherRange) => {
  return parseFloat(val) > parseFloat(higherRange) ? '#ff5733' : '#397ed0';
};

export const removeNullfromArray = data => {
  return data?.filter(it => it?.value);
};

export const getPhysicianNavs = queries => {
  const getQueryForPhysicianCompare = queries => {
    const localQueries = { ...queries };
    if (localQueries?.q) {
      localQueries.physician1 = localQueries?.q;
      delete localQueries['q'];
    }

    return localQueries;
  };

  const getQueryForPhysician = queries => {
    const localQueries = { ...queries };
    if (localQueries?.physician1) {
      localQueries.q = localQueries?.physician1;
      delete localQueries['physician1'];
    }
    if (localQueries?.physician2) {
      delete localQueries['physician2'];
    }

    return localQueries;
  };

  const pathPhysician = format({
    pathname: '/physician',
    query: getQueryForPhysician(queries)
  });

  const pathPhyCompare = format({
    pathname: '/physician/analogy',
    query: getQueryForPhysicianCompare(queries)
  });

  const tabs = [];

  if (pathPhysician) {
    tabs.push({
      label: 'Physician-360',
      link: pathPhysician ? pathPhysician : '/physician'
    });
  }

  if (pathPhyCompare) {
    tabs.push({
      label: 'Comparison',
      link: pathPhyCompare ? pathPhyCompare : '/physician/analogy'
    });
  }

  const navs = {
    home: 'Home',
    tabs: tabs
  };

  if (queries?.previousPage) {
    navs.breadCrumbs = getBackPath({
      query: { previousPage: queries?.previousPage }
    })
      ? [
          {
            name: 'Home',
            link: '/overview',
            backPath: getBackPath({
              query: { previousPage: queries?.previousPage }
            })
          },
          {
            name: 'Physician-360',
            link: pathPhysician ? pathPhysician : '/physician'
          }
        ]
      : [];
  }

  return navs;
};
